@import "@styles/vars.scss";
.InfiHumanPossibilitiesSection {
  background-color: #fafafa;
  text-align: center;
  padding: 120px 0px 80px 0px;
  @media screen and (max-width: $screen-sm-max) {
    padding: 62px 0px 57px 0px;
  }

  .Title {
    color: #183a23;
    text-align: center;
    font-size: 72px;
    font-weight: 600;
    line-height: 130%; /* 93.6px */
    margin-bottom: 42px;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 44px;
      font-weight: 700;
      max-width: 80%;
      margin: auto;
      line-height: 100%;
      margin-bottom: 35px;
    }
  }

  .ReviewCard {
    border-radius: 6px;
    background: #cee9de;
    padding: 20px;
    @media screen and (min-width: $screen-sm-max) {
      padding: 38px;
      width: 889px;
      height: 409px;
    }
    .ReviewContent {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .ReviewText {
      color: #1a4348;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      text-align: left;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 24px;
        margin-bottom: 24px;
      }
    }

    .ReviewSubtext {
      color: #000;
      font-size: 20px;
      font-weight: 400;
      text-align: left;
      @media screen and (max-width: $screen-sm-max) {
        display: none;
      }
    }
  }
  .ImageContainer {
    @media screen and (max-width: $screen-sm-max) {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
  .ReviewImage {
    width: 100%;
    height: 100% !important;
    @media screen and (max-width: $screen-sm-max) {
      width: 136px;
      height: 136px !important;
      border-radius: 50%;
    }
  }
  .SideReviewImage {
    width: 230px;
    background-color: #efefef;
    height: 350px;
    @media screen and (max-width: $screen-sm-max) {
      display: none;
    }
  }
}
