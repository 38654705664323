@import "@styles/vars.scss";

.DiscountModal {
  :global {
    .ant-modal-close-x {
      background-color: #fff;
      border-radius: 50%;

      svg {
        font-size: large;
      }
    }
  }
}

.PreviewImage {
  width: 100%;
  border-radius: 12px 12px 0px 0px;
}

.content {
  text-align: center;
  padding: 22px;
  background-color: #f8f7f2;
  border-radius: 0px 0px 12px 12px;
}

.Title {
  color: #141414;
  font-size: 24px !important;
  font-weight: 700;
  line-height: 110%; /* 26.4px */
}

.Desc {
  color: #585858;
  font-size: 16px !important;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  margin: 8px 0px 22px 0px !important;
}

.SubmitBtn {
  border-radius: 20px;
  background: #ff5925 !important;
  color: #fff !important;
  border: none !important;
  font-size: 15px;
  font-weight: 700;
  line-height: 175%; /* 26.25px */
}
